<template>
  <div>
    <div
        style="margin-bottom: 15px;z-index: 8848!important;display: flex;flex-direction: row-reverse;justify-content: space-between">
      <a-pagination
          :default-current="1"
          v-model="curr"
          :pageSize="12"
          :total="this.returnBack.count"
      />

      <a-button type="danger" @click="clearLogger">
        清空日志
      </a-button>
    </div>

    <a-table
        style="border-radius: 15px;overflow: hidden;border: 1px solid rgba(0,0,0,0.2)"
        ref="table"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.pid"
        :pagination="false"
    >
      <!--        eslint-disable-next-line vue/no-unused-vars -->
    </a-table>
  </div>
</template>

<script>
const key = '123'
export default {
  name: "logger",
  data() {
    return {
      columns: [
        {
          title: '登录用户',
          dataIndex: 'user',
          key: 'user',
          align: "center",
          scopedSlots: {customRender: 'user'},
        },
        {
          title: '登录IP',
          dataIndex: 'login_ip',
          key: 'login_ip',
          align: "center",
          scopedSlots: {customRender: 'login_ip'},
        },
        {
          title: '请求API',
          dataIndex: 'request_api',
          key: 'request_api',
          align: "center",
          scopedSlots: {customRender: 'request_api'},
        },
        {
          title: '登录地区',
          dataIndex: 'login_region',
          key: 'login_region',
          align: "center",
          scopedSlots: {customRender: 'login_region'},
        },
        {
          title: '登录时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
          scopedSlots: {customRender: 'create_time'},
        },

      ],
      data: [],
      curr: 1,
      returnBack: {}
    }
  },
  methods: {
    async clearLogger() {
      await this.$axios.post('/Admin/Api/delLog').then(v => this.$message.success({
        content: v.data.msg,
        key,
        duration: 1
      })).catch(err => this.$message.error({content: err.msg, key, duration: 1}))
      setTimeout(() => this.$router.go(0), 1000)
    }

  },
  watch: {
    curr: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/getBackgroundLogList', {
          "page": n,
          "pageCount": 12
        })).data.data)
        this.data = this.returnBack.list
      }
    },
  },
  async created() {
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/getBackgroundLogList', {
      "page": 1,
      "pageCount": 12
    })).data.data)
    this.data = this.returnBack.list

  }
}
</script>

<style scoped>

</style>